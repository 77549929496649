<template>
<div class="detailsDiv divMa">
  <div class="FHZJtou" @click="backPageUp"></div>
  <div class="detailsDiv1">{{details.articleName}}</div>
  <img :src="staticurlSrc + details.image1">
  <div v-html="details.articleContent"></div>
</div>
</template>

<script>
import {staticurl} from "@/utils/baseurl";
import {articleApi} from "@/api/news";
export default {
  name: "informationNewsDetails",
  data(){
    return{
      staticurlSrc:staticurl,
      details:{}
    }
  },
  beforeMount(){
    let articleSid=this.$route.query.articleSid//url带参

    let key="informationNewsDetails"+articleSid;
    console.log(key)
    //sessionStorage.removeItem(key);//清空session
    const session =window.sessionStorage.getItem(key)
    console.log(session)

    if(!session){
      console.log("请求文章详情接口")
       articleApi({articleSid: articleSid,}).then((res) => {
         this.details=res.data.result
         window.sessionStorage.setItem(key, JSON.stringify(this.details))
       });//文章详情
    }else {
      this.details=JSON.parse(window.sessionStorage.getItem(key))
    }
  },
  methods: {
    backPageUp(){
      this.$router.go(-1)//跳转到上一页
    }
  }
}
</script>

<style scoped>
.detailsDiv{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: auto;
}
.detailsDiv img{
  width: 100%;
  border-radius: 10px;
}
.detailsDiv1{
  font-size: 30px;
  font-weight: 600;
  margin: 30px;
}
.FHZJtou{
  height: 30px;
  width: 30px;
  left: 20px;
  top: 20px;
  position: absolute;
  z-index: 333;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/images/FHZJtou.jpg");
  border-radius: 100%;
}
@media (max-width: 600px){
  .detailsDiv1{
    font-size: 20px;
  }
  .FHZJtou{
    left: 10px;
    top: 10px;
  }
}
</style>
